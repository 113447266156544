import * as React from "react"
import { Text, InputGroup } from "@blueprintjs/core"
import { components } from "react-select"
import Pin from "@images/img-min/pin.svg"
import GreyPin from "@images/img-min/greyPin.svg"
import CTARightArrow from "@images/img-min/ctaRightArrow.svg"
import ClientServices from "@services/ClientServices"
import useResizeWidth from "@hooks/useResizeWidth"

// React-Select Custom Components

export const ControlComponent = props => (
  <div style={{ width: "100%" }}>
    <components.Control {...props}>{props.children}</components.Control>
  </div>
)

export const IndicatorsContainer = props => {
  return <></>
}

export const ValueContainer = ({ children, ...props }) => {
  return children
}

export const Menu = props => {
  return (
    <components.Menu className="addressSearchResults" {...props}>
      {props.children}
    </components.Menu>
  )
}

export const MenuList = props => {
  return (
    <components.MenuList {...props} className="addressSearchResultsList">
      {props.children}
    </components.MenuList>
  )
}

export const CustomOption = props => {
  const clientServices = new ClientServices()
  const {
    data: place,
    innerProps,
    isDisabled,
    isFocused,
    isSelected,
    onClick,
  } = props

  if (clientServices.utility.isDefinedWithContent(place)) {
    const displayAddress =
      typeof place["place_name"] !== "undefined"
        ? place["place_name"]
        : place["text"]

    let address = (
      <Text tagName="strong" className="streetNumber">
        {displayAddress}
      </Text>
    )

    return (
      <div
        className={`addressSearchItem ${isFocused ? "selectedItem" : ""}`}
        {...innerProps}
        onClick={onClick}
      >
        <div className="itemDecorator">
          <GreyPin />
        </div>
        <div className="itemText">{address}</div>
      </div>
    )
  } else {
    return <>Empty</>
  }
}

export const Placeholder = props => {
  return <components.Placeholder {...props} />
}

export const LoadingIndicator = () => {
  return <></>
}

export const LoadingMessage = () => {
  return <></>
}

export const NoOptionsMessage = () => {
  return <></>
}

export const ButtonSearch = () => {
  return (
    <div className="button-search">
      Free Risk Assessment
      <CTARightArrow />
    </div>
  )
}

export const Input = props => {
  const { width } = useResizeWidth()

  const [placeholder, setPlaceHolder] = React.useState(
    "Enter street address, city, state, or ZIP code"
  )
  React.useEffect(() => {
    const isMobile = width <= 550
    if (isMobile) setPlaceHolder("Enter street address, city, state...")
  }, [])

  return (
    <div className="addressSearchInput">
      <InputGroup
        large={true}
        leftIcon={<Pin />}
        placeholder={placeholder}
        value={props.value}
        type={props.type}
        tabIndex={props.tabIndex}
        spellCheck={props.spellCheck}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}
        autoCapitalize={props.autoCapitalize}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        rightElement={<ButtonSearch />}
      />
    </div>
  )
}
