import axios from "axios"
import { constants } from "../constants/constants"

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${constants.IP_API_URL}?key=${constants.IP_API_KEY}&fields=status,lat,lon`
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getPlacePredictions = async (
  input: string,
  longitude,
  latitude
) => {
  try {
    let url = `${constants.MAPBOX_URL}/${decodeURIComponent(
      input
    )}.json?access_token=${
      constants.MAPBOX_KEY
    }&country=us&worldview=us&bbox=-124.848974%2C24.396308%2C-66.885444%2C49.384358&language=en`

    url = !(latitude && longitude)
      ? url
      : `${url}&proximity=${longitude},${latitude}`

    const result = await axios.get(url)

    return result.data.features
  } catch (error) {
    return []
  }
}
