import React, { useEffect, useState, useContext } from "react"
import AsyncSelect from "react-select/async"
import ClientServices from "@services/ClientServices"
import {
  Menu,
  IndicatorsContainer,
  ValueContainer,
  Input,
  Placeholder,
  CustomOption,
  ControlComponent,
  MenuList,
  LoadingIndicator,
  LoadingMessage,
  NoOptionsMessage,
} from "./ReactSelect"
import { getPlacePredictions } from "@services/MapBox"
import LocationContext from "../../locationContext/locationContext"

interface AddressSearchProps {
  ref?: React.Ref<any>
  className?: string
  onSelect?: (place?: any) => void
  onSendAddress?: (address?: any, data?: any) => void
  defaultText?: string
  selectProps?: any
}

const AddressSearch: React.FC<AddressSearchProps> = ({
  onSelect = () => {},
  onSendAddress = () => {},
  defaultText = "",
  selectProps = {
    autoFocus: true,
    initFreeze: true,
  },
}) => {
  const clientServices = new ClientServices(),
    [freeze, setFreeze] = useState(selectProps.initFreeze),
    [search, setSearch] = useState(defaultText),
    [value, setValue] = useState(null),
    [results, setResults] = useState([]),
    [menuOpen, setMenuOpen] = useState(false),
    autocompleteRef = React.useRef(null)

  const { lon, lat } = React.useContext(LocationContext)

  const handleItemClicked = place => {
      onSendAddress(encodeURIComponent(place?.place_name))
      onSelect(place?.center)
      setResults([])
    },
    handleSearchChange = value => {
      setSearch(decodeURIComponent(value))
    },
    performSearch = async (
      tmpSearch: React.SetStateAction<string>,
      callback: (arg0: any) => void
    ) => {
      if (!freeze) {
        if (tmpSearch !== "") {
          setSearch(tmpSearch)

          const mapResults = await getPlacePredictions(search, lon, lat)

          if (clientServices.utility.isDefinedWithContent(mapResults)) {
            setResults(() => mapResults)
            callback(mapResults)
          }
        } else {
          setResults([])
        }
      }
    }

  return (
    <>
      <div className="addressSearch">
        <AsyncSelect
          ref={autocompleteRef}
          autoFocus={selectProps.autoFocus}
          loadOptions={performSearch}
          name="address"
          components={{
            Menu,
            IndicatorsContainer,
            ValueContainer,
            Input,
            Placeholder,
            MenuList,
            LoadingIndicator,
            LoadingMessage,
            NoOptionsMessage,
            Control: ControlComponent,
            Option: props => (
              <CustomOption
                {...props}
                onClick={() => handleItemClicked(props.data)}
              />
            ),
          }}
          backspaceRemovesValue={false}
          menuIsOpen={menuOpen}
          isMulti={false}
          options={results.map(place => ({
            value: place.id,
            label: place.place_name,
          }))}
          inputValue={decodeURIComponent(search)}
          value={value}
          isClearable={true}
          isSearchable={true}
          getOptionValue={option => option.value}
          getOptionLabel={option => option.label}
          {...selectProps}
          onKeyDown={e => {
            switch (e.keyCode) {
              case 13:
                e.preventDefault()
                const ref = autocompleteRef.current as any,
                  selectState = ref.select.select.state
                handleItemClicked(selectState.focusedOption)
                break
            }
          }}
          onInputChange={(newValue: string) => {
            newValue = decodeURIComponent(newValue)

            if (!window.addressFreeze) {
              handleSearchChange(newValue)
              return newValue
            }
          }}
          // Events:
          onBlur={() => {
            setFreeze(true)
            setMenuOpen(false)
            window.addressFreeze = true
          }}
          onFocus={() => {
            setFreeze(false)
            setMenuOpen(true)
            window.addressFreeze = false
          }}
        />
      </div>
    </>
  )
}

export default AddressSearch
