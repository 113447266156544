import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import AddressSearch from "../../../ui/AddressSearch/AddressSearch"
import { customRoutes } from "@utils/customRoutes"

const InputSearch = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCity {
        nodes {
          slug {
            current
          }
          city
          state {
            slug {
              current
            }
            state
          }
        }
      }
      allSanityNewState {
        nodes {
          state
        }
      }
    }
  `)

  const handleRoute = value => {
    let location = ""
    let mainCity = null
    let state = null
    let route = `/report?address=${value}`

    const decodedPlace = decodeURIComponent(value)
    const place0 = decodedPlace.split(", ")[0]
    const place1 = decodedPlace.split(", ")[1]


    if (decodedPlace.split(", ").length <= 2) {
      customRoutes.map(element => {
        if (element.place === place0 || element.route === place0) {
          location = element.route
        }
      })
    }

    if (decodedPlace.split(", ").length === 3) {
      mainCity = data.allSanityCity?.nodes.find(item => {
        return (
          item.city.toUpperCase() === place0.toUpperCase() &&
          item.state?.state.toUpperCase() === place1.toUpperCase()
        )
      })

      state = data.allSanityNewState?.nodes.find(item => {
        return (
          item.state?.toUpperCase() === place1.toUpperCase()
        )
      })

      if (typeof mainCity == "undefined" && typeof state == "undefined") {
        mainCity = data.allSanityCity?.nodes.find(item => {
          return item.city.toUpperCase() == place0.toUpperCase()
        })
      }
    }

    if (mainCity) {
      location = `${mainCity.slug?.current}`
    }

    /* CHECK IF THE ADDRESS TYPED IS rolling hills california AND IF SO REDIRECT TO /california */
    const rollingHillsRoute =
      "/report?address=8540%20Rolling%20Hills%20Drive%2C%20Corona%2C%20California%2092883%2C%20United%20States"

    if (route === rollingHillsRoute) {
      navigate("/california")
    } else {
      location !== "" ? navigate(`/${location}`) : window.location.href = route
    }
  }

  return <AddressSearch onSendAddress={handleRoute} />
}

export default InputSearch
