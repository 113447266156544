export const customRoutes = [
  { place: "Alabama", route: "alabama" },
  { place: "Arizona", route: "arizona" },
  { place: "Arkansas", route: "arkansas" },
  { place: "California", route: "california" },
  { place: "Colorado", route: "colorado" },
  { place: "Connecticut", route: "connecticut" },
  { place: "Delaware", route: "delaware" },
  { place: "Florida", route: "florida" },
  { place: "Georgia", route: "georgia" },
  { place: "Idaho", route: "idaho" },
  { place: "Illinois", route: "illinois" },
  { place: "Indiana", route: "indiana" },
  { place: "Iowa", route: "iowa" },
  { place: "Kansas", route: "kansas" },
  { place: "Kentucky", route: "kentucky" },
  { place: "Louisiana", route: "louisiana" },
  { place: "Maine", route: "maine" },
  { place: "Maryland", route: "maryland" },
  { place: "Massachusetts", route: "massachusetts" },
  { place: "Michigan", route: "michigan" },
  { place: "Minnesota", route: "minnesota" },
  { place: "Mississippi", route: "mississippi" },
  { place: "Missouri", route: "missouri" },
  { place: "Montana", route: "montana" },
  { place: "Nebraska", route: "nebraska" },
  { place: "Nevada", route: "nevada" },
  { place: "New Hampshire", route: "newhampshire" },
  { place: "New Jersey", route: "newjersey" },
  { place: "New Mexico", route: "newmexico" },
  { place: "New York", route: "newyork" },
  { place: "North Carolina", route: "northcarolina" },
  { place: "North Dakota", route: "northdakota" },
  { place: "Ohio", route: "ohio" },
  { place: "Oklahoma", route: "oklahoma" },
  { place: "Oregon", route: "oregon" },
  { place: "Pennsylvania", route: "pennsylvania" },
  { place: "Rhode Island", route: "rhodeisland" },
  { place: "South Carolina", route: "southcarolina" },
  { place: "South Dakota", route: "southdakota" },
  { place: "Tennessee", route: "tennessee" },
  { place: "Texas", route: "texas" },
  { place: "Utah", route: "utah" },
  { place: "Vermont", route: "vermont" },
  { place: "Virginia", route: "virginia" },
  { place: "Washington", route: "washington" },
  { place: "West Virginia", route: "westvirginia" },
  { place: "Wisconsin", route: "wisconsin" },
  { place: "Wyoming", route: "wyoming" },
]

export const customCities = [
  {
    city: "Los Angeles",
    slug: {
      current: "los-angeles",
    },
    state: {
      state: "California",
      slug: {
        current: "california",
      },
    },
  },
]
