import { useState, useEffect } from "react";

const getWidth = () => (typeof window !== "undefined" ? window.innerWidth : 0);

export default function useResizeWidth() {
  const [width, setWidth] = useState(getWidth());

  const handleSetWidth = () => {
    const newWidth = getWidth();
    setWidth(newWidth);
  };
  useEffect(() => {
    
    window.addEventListener("resize", handleSetWidth);
    handleSetWidth();
    return () => window.removeEventListener("resize", handleSetWidth);
  }, []);

  return { width };
}
